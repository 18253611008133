<template>
    <div class="hangorder" :style="{'background-color':main_color}">
        <div class="title">挂单
            <div class="back" @click="backHangOrder" :style="{'color':main_color}">退出挂单</div>
        </div>
        <div class="main">
            <div class="table_head" :style="{'color':main_color}">
                <div class="table_head_bg" :style="{'background-color':main_color}"></div>
                <div class="table_head_item item2">商品信息</div>
                <div class="table_head_item item">单数/数量</div>
                <div class="table_head_item item">总价</div>
                <div class="table_head_item item">折扣</div>
                <div class="table_head_item item">应收</div>
                <div class="table_head_item item3">会员信息</div>
                <div class="table_head_item item3">操作时间</div>
                <div class="table_head_item item3">操作</div>
            </div>
            <div class="order">
                <div class="order_item" v-for="(item,index) in order" :key="item.code">
                    <div class="goods_item" v-for="(g_item,g_index) in item.goods" :key="g_item.id"
                    v-show="item.isOpen || g_index==0">
                        <div class="goodsinfo item2">
                            <img :src="g_item.goods_picture" class="g_item_image">
                            <div class="g_item_info">
                                <div class="g_item_goodsname">{{g_item.goods_name}}</div>
                                <div v-if="g_item.is_spec==1" class="g_item_goodspec">规格：{{g_item.is_spec==1?g_item.goods_spec.title:''}}</div>
                            </div>
                        </div>
                        <div class="item">
                            <div>￥{{g_item.unitprice}}</div>
                            <div>×{{g_item.count}}</div>
                        </div>
                        <div class="item">￥{{allPrice(g_item).toFixed(2)}}</div>
                        <div class="item">￥{{discountPrice(item.memberinfo,g_item).toFixed(2)}}</div>
                        <div class="item">￥{{(allPrice(g_item)-discountPrice(item.memberinfo,g_item)).toFixed(2)}}</div>
                        <div class="item3">
                            <template v-if="g_index==0&&item.memberinfo">
                                <div>{{item.memberinfo.name}}</div>
                                <div>{{item.memberinfo.mobile}}</div>
                            </template>
                        </div>
                        <div class="item3">
                             <template v-if="g_index==0">{{handleTime(item.code)}}</template>
                        </div>
                        <div class="operate item3">
                            <template v-if="g_index==0">
                                <div class="continue" :style="{'background-color':main_color}"
                                @click="continueOrder(item)">取单收银</div>
                                <div class="delete" :style="{'--color':main_color}"
                                 @click="deleteOrder(index)">作废</div>
                            </template>
                        </div>
                    </div>
                    <div :style="{'color':main_color,'margin-left':'135px','cursor':'pointer'}"
                    v-if="item.goods.length>1" @click="handleOpen(item)">{{item.isOpen?'收起':'展开'}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getDateformat } from '@/util/getDate';
export default {
	data(){
		return{
            main_color:"",
            imageHttp:'https://boweisou.oss-cn-shenzhen.aliyuncs.com/z_Cashier/',
            order:[]
		}
	},
    created(){
        this.getColorScheme()
        let order =  JSON.parse(sessionStorage.getItem('cashier_order'))
        order.forEach(item=>{
            item.isOpen = true
        })
        this.order = order
    },
    methods:{
        // 退出取单
        backHangOrder(){
            sessionStorage.removeItem('hangorder')
            this.$router.push({
                path:'/cashier'
            })
        },
        // 获取主题颜色
        getColorScheme(){
            this.$axios.post(this.$api.cashier.GetColorScheme)
            .then(res=>{
                if(res.code == 0){
                    this.main_color = res.result.main_color
                }
            })
        },
        //总价
        allPrice(item){
            return item.count*item.unitprice
        },
        //折扣金额
        discountPrice(merberinfo,item){
            let price = 0
            if(merberinfo){
                price = this.allPrice(item)*(10-merberinfo.discount)*0.1
            }
            return price
        },
        handleTime(time){
            return getDateformat(new Date(time).getTime() / 1000);
        },
        // 展开、收起订单
        handleOpen(item){
            item.isOpen = !item.isOpen
        },
        // 继续收银
        continueOrder(item){
            sessionStorage.setItem('hangorder',JSON.stringify(item))
            this.$router.push({
                name:'cashier'
            })
        },
        // 作废
        deleteOrder(index){
            this.$confirm('确定将此条数据作废？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.order.splice(index,1)
               sessionStorage.setItem('cashier_order',JSON.stringify(this.order))
            }).catch(()=>{})
        }
    }
}
</script>

<style lang="less" scope>
.hangorder{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0 24px 24px;
    display: flex;
    flex-direction: column;
    .title{
        height: 52px;
        text-align: center;
        line-height: 52px;
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        position: relative;
        .back{
            position: absolute;
            top:8px;
            bottom: 8px;
            right: 0;
            padding: 0 24px;
            line-height: 36px;
            text-align: center;
            cursor: pointer;
            font-size: 17px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            background-color: #FFFFFF;
            border-radius: 3px;
        }
    }
     .main{
        display: flex;
        width: 100%;
        overflow: hidden;
        flex: 1;
        height: 0;
        flex-direction: column;
        background-color: #fff;
        .table_head{
            width: 100%;
            height: 60px;
            position: relative;
            display: flex;
            align-items: center;
            .table_head_item{
                text-align: center;
                font-size: 17px;
                font-family: Source Han Sans CN;
                font-weight: 500;
            }
            .table_head_bg{
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                opacity: 0.12;
            }
        }
        .item{
            flex: 1;
            text-align: center;
        }
        .item2 {
            flex: 2.3;
        }
        .item3 {
            flex: 1.3;
            text-align: center;
        }
        .order{
            flex:1;
            overflow-y: auto;
            .order_item{
                padding: 10px 0;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #666666;
                line-height: 24px;
                border-bottom: 1px solid #e1e1e1;
                .goods_item{
                    display: flex;
                    padding: 14px 0;
                    align-items: center;
                    .goodsinfo{
                        display: flex;
                        .g_item_image{
                            width: 88px;
                            height: 88px;
                            border-radius: 3px;
                            margin-left: 30px;
                            margin-right: 16px;
                        }
                        .g_item_info{
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                        }
                        .g_item_goodsname{
                            color: #333333;
                            overflow:hidden;
                            text-overflow:ellipsis;
                            display:-webkit-box;
                            -webkit-box-orient:vertical;
                            -webkit-line-clamp:2;
                        }
                        .g_item_goodspec{
                            font-size: 14px;
                        }
                    }
                    .operate{
                        display: flex;
                        line-height: 36px;
                        justify-content: center;
                        cursor: pointer;
                        .continue{
                            width: 33%;
                            height: 36px;
                            border-radius: 3px;
                            font-size: 14px;
                            font-family: Source Han Sans CN;
                            font-weight: 500;
                            color: #FFFFFF;
                            margin-right: 20px;
                        }
                        .delete{
                            width: 33%;
                            height: 36px;
                            border-radius: 3px;
                            border: 1px solid var(--color);
                            color:var(--color);
                            font-size: 14px;
                            font-family: Source Han Sans CN;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}
</style>
